module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EOS Technology","short_name":"EOS Technology","start_url":"/","background_color":"#fff","theme_color":"#028bab","icon":"src/images/icon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"982692826d6f2bfe66b07a9d2e87d60a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","generateDefaultLanguagePage":false,"redirect":false,"siteUrl":"https://eos-technology.eu","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"returnObjects":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-0G9ZWFMZLB","anonymize":true},"facebookPixel":{"pixelId":""},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
